<template>
  <section id="mobile-app" class="mobile-app">
    <h2 class="mobile-app__title section-title">{{ title }}</h2>
    <p class="mobile-app__text">{{ text }}</p>
    <div class="mobile-app__container">
      <div class="mobile-app__tabs">
        <a
          href="https://play.google.com/store/apps/details?id=ru.logicstar.CheryPersonal&hl=ru&gl=US&pli=1"
          target="_blank"
          class="mobile-app__google mobile-app__tab"
        >
          <img
            class="mobile-app__google-image"
            src="../../img/icons/google-play-store-g.svg"
            alt="play store icon"
          />
          <p class="mobile-app__google-text">
            <span>Скачать в </span>Google play
          </p>
        </a>
        <a
          href="https://apps.apple.com/ru/app/%D0%BB%D0%B8%D1%87%D0%BD%D1%8B%D0%B9-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-chery/id1571172622"
          target="_blank"
          class="mobile-app__apple mobile-app__tab"
        >
          <img
            class="mobile-app__apple-image"
            src="../../img/icons/app-store-g.svg"
            alt="app store icon"
          />
          <p class="mobile-app__apple-text"><span>Скачать в </span>App Store</p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import siteData from "@/config.json";

export default {
  name: "MobileApp",
  data() {
    return {
      title: siteData.mobile_app.title,
      text: siteData.mobile_app.text,
    };
  },
};
</script>

<style lang="scss" scoped></style>
