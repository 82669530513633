<template>
  <section id="advantages" class="advantages">
    <h2 class="advantages__title section-title">{{ title }}</h2>
    <div class="advantages__content">
      <ul class="advantages__list">
        <li
          v-for="(item, index) in list"
          :key="index"
          class="advantages__list-item"
        >
          <img
            :src="require(`../../img/icons/advantages/${item.img}.svg`)"
            :alt="item.text"
            class="advantages__list-item__image"
          />
          <p class="advantages__list-item__text">{{ item.text }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import siteData from "@/config.json";
export default {
  name: "AdvantagesComponent",
  data() {
    return {
      title: siteData.advantages.title,
      list: siteData.advantages.list,
    };
  },
};
</script>

<style lang="scss" scoped></style>
