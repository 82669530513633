<template>
  <section id="modelsRange" class="models">
    <h2 class="models__title">{{ title }}</h2>
    <div class="models__menu">
      <a
        class="models__menu__item"
        v-for="model in models_menu"
        :href="'#' + model.id"
        :key="model.id"
      >
        {{ model.name }}
      </a>
    </div>
    <ModelComponent />
  </section>
</template>

<script>
import siteData from "@/config.json";
import ModelComponent from "./ModelComponent.vue";

export default {
  name: "ModelsComponent",
  data() {
    return {
      title: siteData.models_range.title,
      models_menu: siteData.models_range.models_menu,
    };
  },
  components: { ModelComponent },
};
</script>
