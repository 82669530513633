<template>
  <section id="main-slider-1" class="main-swiper-container">
    <swiper class="swiper-main" :modules="modules" :slides-per-view="1" :pagination="{ clickable: true }" :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
    }">
      <swiper-slide class="swiper-main-slide swiper-main-slide--counter">
        <div class="first-swiper__image first-swiper__image-1"></div>
        <div class="swiper-main-slide__content">
          <div class="swiper-main-slide__counter">
            <TimeCounter />
          </div>
          <button @click="toggleRequestForm($event)" class="swiper-main-slide__content__button btn-primary">
            Зафиксируйте лучшие условия
          </button>
        </div>
      </swiper-slide>


      <!-- <swiper-slide class="swiper-main-slide">
        <div class="swiper-main-slide-2">
          <div class="first-swiper__image">
            <video autoplay muted loop class="desktop">
              <source src="@/img/pics/backgrounds/main-slider/hero-gif.gif" type="video/mp4" />
            </video>
            <video autoplay muted loop class="mobile">
              <source src="@/img/pics/backgrounds/main-slider/hero-gif.gif" type="video/mp4" />
            </video>
            <div class="first-swiper__image-mobile first-swiper__image-mobile-1"></div>
          </div>
        </div>
        <div class="swiper-main-slide__content">
          <h2 class="swiper-main-slide__title swiper-main-slide__title--md">
            Купите Chery ниже себестоимости с выгодой до 20% <br> Акция до 30.04
          </h2>
          <button @click="toggleRequestForm($event)" class="swiper-main-slide__content__button btn-primary">
            Отправить заявку
          </button>
        </div>
      </swiper-slide> -->
    </swiper>
  </section>
</template>
<!--  -->

<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import TimeCounter from "./TimeCounter.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    TimeCounter,
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: 'Оставьте заявку на звонок менеджера и зафиксируйте лучшие условия на покупку Chery',
        form_position: "формы  Зафиксируйте лучшие условия ",
      });
    },
  },
};
</script>
