<template>
  <section id="promo" class="main-swiper-container">
    <swiper class="swiper-main" :modules="modules" :slides-per-view="1" :pagination="{ clickable: true }" :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
    }">
      <swiper-slide class="swiper-main-slide">
        <SliderComponent>
          <template #image>
            <div class="second-swiper__image">
              <div class="second-swiper__image-mobile second-swiper__image-mobile-1"></div>
              <div class="second-swiper__image-desktop second-swiper__image-desktop-1"></div>
            </div>
          </template>

          <template #title>
            Новый Chery без доплат с выгодой по Trade-In до 20%
          </template>
          <template #sliderButton> Получить предложение </template>
        </SliderComponent>
      </swiper-slide>
      <swiper-slide class="swiper-main-slide">
        <SliderComponent>
          <template #image>
            <div class="second-swiper__image">
              <div class="second-swiper__image-mobile second-swiper__image-mobile-4"></div>
              <div class="second-swiper__image-desktop second-swiper__image-desktop-4"></div>
            </div>
          </template>

          <template #title>
            Премиальный внедорожник 8 pro max new с выгодой <br>до <span style="display: inline-block">20%</span>
          </template>
          <template #sliderButton>Получить выгоду</template>
        </SliderComponent>
      </swiper-slide>
      <swiper-slide class="swiper-main-slide">
        <SliderComponent>
          <template #image>
            <div class="second-swiper__image">
              <div class="second-swiper__image-mobile second-swiper__image-mobile-3"></div>
              <div class="second-swiper__image-desktop second-swiper__image-desktop-3"></div>
            </div>
          </template>

          <template #title>
            {{ sliders.slider2.credit }} <br />
            {{ sliders.slider2.text }}
          </template>
          <template #sliderButton>
            {{ know_more }}
          </template>
        </SliderComponent>
      </swiper-slide>

      <swiper-slide class="swiper-main-slide">
        <SliderComponent :is-view-btn="false">
          <template #image>
            <div class="second-swiper__image">
              <div class="second-swiper__image-mobile second-swiper__image-mobile-5"></div>
              <div class="second-swiper__image-desktop second-swiper__image-desktop-5"></div>
            </div>
          </template>

          <template #title>
            При покупке Chery зимняя резина + ТО&nbsp;в&nbsp;подарок
          </template>
        </SliderComponent>
      </swiper-slide>
    </swiper>
  </section>
</template>
<!--  -->
<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import SliderComponent from "./SliderComponent.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    SliderComponent,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: '',
        form_position: "формы Тест-драйв акция гибрид",
        showDate: true
      });
    },
  },
};
</script>
