<template>
  <section id="header">
    <nav id="navMob" class="navbar__mobile" :class="{ sticky: offset }">
      <Transition name="fade">
        <span v-show="!isHidden" class="navbar__mobile-bg"></span>
      </Transition>
      <div class="navbar__mobile__logo">
        <img src="@/img/common/logo-g.svg" alt="" class="navbar__mobile__logo">
      </div>
      <button @click="toggleRequestForm" class="navbar__mobile__form">
        <img src="@/img/icons/call-answer-g.svg" alt="phone icon" />
      </button>
      <button :class="{ hide: !isHidden }" class="navbar__mobile__burger-btn" @click="toggleNavlinks">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <Transition name="slideMenu">
        <div class="navbar__mobile__links" v-show="!isHidden">
          <a v-for="(link, index) in navbar" :key="{ index }" :href="`#${link.id}`" class="navbar__mobile__links-item"
            @click="toggleNavlinks()">{{ link.name }}</a>
          <div class="navbar__mobile__links-contacts">
            <div v-for="contact in contacts" :key="contact.phone" class="navbar__mobile__links-contact">
              <p>
                <span><img src="@/img/icons/map_bullit-g.svg" alt="" /></span>
                {{ contact.street }}
              </p>
              <a :href="`tel:${contact.phone_number}`">{{
                contact.phone_text
                }}</a>
            </div>
          </div>
          <div class="navbar__mobile__links-socials">
            <a href="https://web.telegram.org/z/#-1770343263" target="_blank">
              <img src="@/img/icons/telegram-gray-g.svg" alt="telegram logo" /></a>
            <a href="https://vk.com/avtoban_chery" target="_blank">
              <img src="@/img/icons/vk-gray-g.svg" alt="VK logo" /></a>
          </div>
        </div>
      </Transition>
    </nav>

    <nav id="navDesk" class="header__desktop" :class="{ sticky: offset }">
      <div class="header__contacts">
        <div v-for="contact in contacts" :key="contact.phone" class="header__contact">
          <p>
            <span><img src="@/img/icons/map_bullit-g.svg" alt="map marker icon" /></span>
            {{ contact.city }} {{ contact.street }}
          </p>
          <a :href="`tel:{contact.phone_number}`">{{ contact.phone_text }}</a>
        </div>
      </div>
      <div class="header__navbar">
        <div class="header__navbar__logo">
          <img src="@/img/common/logo-g.svg" alt="" class="header__navbar__logo">
        </div>
        <div class="header__navbar__links">
          <a v-for="(link, index) in navbar" :key="{ index }" :href="`#${link.id}`"
            class="header__navbar__links-item">{{ link.name }}</a>
        </div>
        <button @click="toggleRequestForm($event)" class="header__button btn-primary">
          {{ order_call }}
        </button>
      </div>
    </nav>
  </section>
</template>

<script>
import siteData from "@/config.json";

export default {
  name: "HeaderComponent",
  data() {
    return {
      navbar: siteData.navbar,
      contacts: siteData.contacts,
      order_call: siteData.order_call,
      isHidden: true,
      offset: false,
      offsetDesk: false,
    };
  },
  methods: {
    toggleNavlinks() {
      this.isHidden = !this.isHidden;
      this.emitter.emit("open-request-form", {
        open: true,
      });
    },
    toggleRequestForm() {
      this.emitter.emit("open-request-form", {
        open: false,
        formPosition: "Панель навигации",
      });
      if (this.isHidden === false) {
        this.isHidden = true;
      }
    },
  },
  mounted() {
    window.document.onscroll = () => {
      let navMob = document.getElementById("navMob");
      // let navDesk = document.getElementById("navDesk");
      if (window.scrollY > navMob.offsetTop) {
        this.offset = true;
      } else {
        this.offset = false;
      }
      // if (window.scrollY >= navDesk.offsetTop ) {
      //   this.offsetDesk = true;
      // } else {
      //   this.offsetDesk = false;
      // }
    };
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slideMenu-enter-active {
  transition: all 0.3s ease-out;
}

.slideMenu-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slideMenu-enter-from,
.slideMenu-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
</style>
