<template>
  <div class="offer" @click="toggleRequestForm($event)">
    <div class="offer__image">
      <img src="@/img/pics/offer.png" alt="СУПЕР выгод на покупку нового автомобиля CHERY">
    </div>
    <div class="offer__text">
      <p>Только неделю в январе в <span>АВТОБАНЕ</span> мы откроем вам секреты <span>СУПЕР</span> выгод на покупку нового
        автомобиля
        <span>CHERY</span> при личном
        посещении автосалона. <br> Работаем до последнего клиента и авто!
      </p>
      <p>Более <span>200</span> автомобилей всего модельного ряда со склада с ПТС по специальным ценам. Не упустите свой
        шанс - позвоните и назначьте встречу на удобное ВАМ время, пусть даже это будет ночь! <br> Успейте загадать свое
        желание,
        обладать <span>CHERY</span> и осуществить его!</p>
      <p>Мы находимся по адресу: <br> г. Березовский, Березовский тракт, 11а</p>
    </div>
  </div>
</template>

<script>
export default {
methods: {
    toggleRequestForm(event, model) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: "Записаться на встречу",
        name: "ЕКАД",
        form_position: "форма Ночь распродаж ЕКАД",
      });
    },
},
}
</script>